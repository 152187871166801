import axios from 'axios'
import { ElMessage } from 'element-plus'
import router from '@/router'
const service = axios.create({
  //   baseURL: process.env.VUE_APP_BASE_API,
  baseURL: '/api',
  timeout: 5000,
  withCredentials: true
  //   headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
})

service.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

// service.defaults.headers.post['Content-Type'] =
//   'application/x-www-form-urlencoded'
// service.headers.post = 'application/x-www-form-urlencoded'
service.interceptors.response.use(
  (response) => {
    const { data, code, msg } = response.data
    // console.log(response)
    if (code === 200 || code === 201 || code === 0) {
      return data
    } else {
      ElMessage.error(msg)
      return Promise.reject(new Error(msg))
    }
  },
  (error) => {
    const { status } = error.response
    if (status === 401) {
      localStorage.clear()
      router.replace('/login/')
      ElMessage.error('请重新登录')
      return Promise.reject(new Error('请重新登录'))
    } else {
      error.response && ElMessage.error(error.response.data)
      return Promise.reject(new Error(error.response.data))
    }
  }
)

export default service
