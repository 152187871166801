import { login as loginApi, logout } from '@/api/user'
import router from '@/router'
export default {
  namespaced: true,
  state: () => ({
    //   默认显示 siderbar
    sidebar: true,
    token: localStorage.getItem('token') || ''
  }),
  mutations: {
    //   commit
    changeSidebar(state) {
      state.sidebar = !state.sidebar
    },
    setToken(state, token) {
      state.token = token
      localStorage.setItem('token', token)
    }
  },
  actions: {
    //   dispatch
    // changeSidebar({ commit }) {
    //   return Promise((resolve, reject) => {
    //     commit('changeSidebar')
    //     resolve()
    //   })
    // }
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginApi(userInfo)
          .then((res) => {
            commit('setToken', res)
            router.replace(`/${sessionStorage.getItem('path') || 'category'}`)
            resolve()
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    logout({ commit }) {
      commit('setToken', '')
      localStorage.clear()
      router.replace('/login')
    }
  }
}
