import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/LoginView.vue')
  },
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/layout/LayoutIndex.vue'),
    redirect: '/category',
    children: [
      {
        path: 'category',
        name: 'category',
        component: () => import('@/views/category/CategoryView.vue')
      },
      {
        path: 'model',
        name: 'model',
        component: () => import('@/views/model/ModelView.vue')
      },
      {
        path: 'hot',
        name: 'hot',
        component: () => import('@/views/model/Hot.vue')
      },
      {
        path: 'orderList',
        name: 'orderList',
        component: () => import('@/views/order/list/ListView.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
